import { useState } from "react"
import Swal from "sweetalert2"
function BlogUpload() {

    const [title, setTitle] = useState("")
    const [category, setCategory] = useState("")
    const [description, setDescrption] = useState("")
    const [images, setImages] = useState(document.getElementById("images"))
    const [link, setLink] = useState("")
    const [loading, setLoading] = useState(false)

    const handleSubmit = (e) => {
        e.preventDefault()
        setImages(document.getElementById("images"))
        const body = new FormData()
        if (images) {
            body.append("file", images.files[0])
        }
        body.append("title", title)
        body.append("category", category)
        body.append("description", description)
        body.append("link", link)
        setLoading(true)
        fetch(`${process.env.REACT_APP_API_URL}/api/blog`, {
            body,
            method: "POST",
        })
        .then((res)=>{
            console.log(res)
        })
        .finally(()=>{
            Swal.fire("Entrada Agregada")
            setLoading(false)
            setTitle("")
            setCategory("")
            setDescrption("")
            setLink("")
            
        })
    }

    return (
        <div className="container">
            {loading ?
                <div>
                    <div className="d-flex justify-content-center">
                        <div className="spinner-border" role="status">
                            <span className="visually-hidden">Loading...</span>
                        </div>
                    </div>
                </div> :
                <form onSubmit={handleSubmit}>
                    <div className="row">
                        <div className="col">

                            <div className="mb-3">
                                <label htmlFor="images" className="form-label">Imagen</label>
                                <input className="form-control" type="file" id="images" onChange={() => setImages(document.getElementById("images"))} />
                            </div>

                        </div>
                    </div>
                    <div className="row">
                        <div className="mb-3">
                            <label htmlFor="title" className="form-label">Titulo</label>
                            <input type="text" className="form-control" id="title" placeholder="Titulo" value={title} onChange={(e) => setTitle(e.target.value)} />
                        </div>
                        <div className="mb-3">
                            <label htmlFor="category" className="form-label">Categoria</label>
                            <input type="text" className="form-control" id="category" placeholder="Categoria" value={category} onChange={(e) => setCategory(e.target.value)} />
                        </div>
                        <div className="mb-3">
                            <label htmlFor="description" className="form-label">Descripcion</label>
                            <textarea className="form-control" id="description" rows="3" value={description} onChange={(e) => setDescrption(e.target.value)}></textarea>
                        </div>
                        <div className="mb-3">
                            <label htmlFor="link" className="form-label">Link</label>
                            <input type="text" className="form-control" id="link" placeholder="Link" value={link} onChange={(e) => setLink(e.target.value)} />
                        </div>
                        <div className="col-auto">
                            <button type="submit" className="btn btn-primary mb-3">enviar</button>
                        </div>
                    </div>
                </form>
            }

        </div>
    )
}

export default BlogUpload