import axios from "axios"
import {useEffect, useState } from "react"
import { Navigate, Outlet } from "react-router-dom"


function Protected(){
    const [admin,setAdmin]=useState(null)
    useEffect(()=>{
        axios(`${process.env.REACT_APP_API_URL}/api/authrev`,{withCredentials:true})
        .then(response=>{
            setAdmin(response.data.admin)
            
            
        })
        .catch(e=>{
            console.log(e.response.data.admin)
            setAdmin(e.response.data.admin)
        })
           return setAdmin(null)
        },[])
        console.log(admin)
        if (admin===false){
            return <Navigate to="/" />
        }else if(admin===true){
            return <Outlet/>
        }
        
}

export default Protected