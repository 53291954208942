import imagenFAQTitle from "@images/imgPreguntasFrecuentes.png"
import { useInView, animated } from "@react-spring/web"
function Faq() {
    const [ref, inView] = useInView()
    return (
        <animated.div ref={ref} style={{ opacity: inView ? 1 : 0, transition: "1.5s ease-in-out" }}>
            <div className="container" id="faq">

                <div className="row faqRow">
                    <div className="d-flex col justify-content-center subtitles">
                        <img src={imagenFAQTitle} className="img-fluid faqImg" alt="..." />
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        <p className="d-block-flex gap-1">
                            <a className="btn subtitles2 terapiaText" data-bs-toggle="collapse" href="#faq1" role="button" aria-expanded="false" aria-controls="collapseExample">
                                + ¿Cuánto dura el tratamiento?
                            </a>
                        </p>
                        <div className="collapse" id="faq1">
                            <div className="card card-body textCustom myCustomFaqAnswer">
                                El tiempo suele variar según el motivo de consulta, la frecuencia de las sesiones y de cada situación en particular según la historia y los recursos de las personas. Hasta no realizar la evaluación inicial, no será adecuado brindar un tiempo. Igualmente, semana a semana podrás ir evaluando el proceso y su progreso.
                            </div>
                        </div>
                        <p className="d-block-flex gap-1">
                            <a className="btn subtitles2 terapiaText" data-bs-toggle="collapse" href="#faq1.1" role="button" aria-expanded="false" aria-controls="collapseExample">
                                + ¿Tendré siempre el mismo profesional o sesión a sesión serán diferentes?
                            </a>
                        </p>
                        <div className="collapse" id="faq1.1">
                            <div className="card card-body textCustom myCustomFaqAnswer">
                                El profesional con el que iniciarás será siempre el mismo debido a que será quien te prestará el servicio de atención psicológica. A su vez, tener el mismo profesional genera continuidad del proceso.
                            </div>
                        </div>
                        <p className="d-block-flex gap-1">
                            <a className="btn subtitles2 terapiaText" data-bs-toggle="collapse" href="#faq2" role="button" aria-expanded="false" aria-controls="collapseExample">
                                +	¿Cómo puedo acceder a uno de los servicios que ofrece Psicología en línea Conecta?
                            </a>
                        </p>
                        <div className="collapse" id="faq2">
                            <div className="card card-body textCustom myCustomFaqAnswer">
                                Puedes comunicarte a través de nuestras redes sociales (WhatsApp/Instagram/Facebook) o, a través de mail y/o el buzón de contacto de nuestra web.
                                <br />Allí puedes aclarar dudas que tengas sobre el espacio y/o concretar un primer encuentro.
                                En este primer encuentro se conversará respecto de los motivos de consulta, acerca del proceso y la modalidad virtual. Resolver dudas y evaluaremos conjuntamente que la modalidad online sea adecuada para tu situación.
                                <br />Conversaremos respecto de la disponibilidad y elegiremos y agendaremos un día y horario para iniciar el espacio psicológico.
                                Las sesiones y/o espacios suelen tener una duración promedio de 45 minutos.
                                Se manejan sesiones individuales o paquetes de sesiones.

                            </div>
                        </div>
                        <p className="d-block-flex gap-1">
                            <a className="btn subtitles2 terapiaText" data-bs-toggle="collapse" href="#faq3" role="button" aria-expanded="false" aria-controls="collapseExample">
                                +	¿Qué necesito para realizar terapia psicológica en modalidad online?
                            </a>
                        </p>
                        <div className="collapse" id="faq3">
                            <div className="card card-body textCustom myCustomFaqAnswer">
                            Será necesaria conexión a Internet estable desde tu celular y/o computador. También deberás contar con un espacio privado en donde te sientas a gusto y seguro de hablar sin que otras personas te escuchen y se sugiere el uso de auriculares para mejorar la calidad del sonido.
                            </div>
                        </div>
                        <p className="d-block-flex gap-1">
                            <a className="btn subtitles2 terapiaText" data-bs-toggle="collapse" href="#faq4" role="button" aria-expanded="false" aria-controls="collapseExample">
                                +	¿Soy menor de edad puedo tener un espacio psicológico en Psicología en línea Conecta?
                            </a>
                        </p>
                        <div className="collapse" id="faq4">
                            <div className="card card-body textCustom myCustomFaqAnswer">
                                Si te encuentras entre los 14 y 17 años inclusive, y resides en Argentina o Colombia, se evaluará la posibilidad de brindar la atención psicológica, si resides en países diferentes a estos, NO ES POSIBLE, debido a que cada país tiene regulaciones diferentes para la atención en salud a menores de edad. No obstante, en estos dos países será necesario que su padre/madre y/o representante legal se comunique y brinde autorización expresa de ello.
                                <br />Será necesario que sea el adulto quien se ponga en contacto con nosotros, partícipe del primer encuentro y se comprobará el vínculo a través de documentación que así lo acredite. No se atenderá a menores de edad sin la presencia de su representante adulto, quien brindará el consentimiento para el inicio del espacio.
                                <br />Si eres menor de 14 años, lastimosamente a través de este espacio, no podremos brindarte atención.


                            </div>
                        </div>
                        <p className="d-block-flex gap-1">
                            <a className="btn subtitles2 terapiaText" data-bs-toggle="collapse" href="#faq4.1" role="button" aria-expanded="false" aria-controls="collapseExample">
                                +   ¿Puedo atenderme con un psicólogo que reside en un país diferente a donde vivo?
                            </a>
                        </p>
                        <div className="collapse" id="faq4.1">
                            <div className="card card-body textCustom myCustomFaqAnswer">
                                Sí, como mayor de edad tú decides acceder a servicios profesionales diferentes a tu lugar de residencia; muchas personas acceden a productos y servicios de diferentes lugares del mundo.
                                <br />El profesional que te atiende debe estar habilitado profesionalmente para el ejercicio de la profesional desde su lugar de residencia y contar con los registros y habilitaciones necesarios para tal fin. Aquí solo encontrarás profesionales que se encuentran habilitados en sus países de residencia.


                            </div>
                        </div>

                        <p className="d-block-flex gap-1">
                            <a className="btn subtitles2 terapiaText" data-bs-toggle="collapse" href="#faq5" role="button" aria-expanded="false" aria-controls="collapseExample">
                                +	¿Te surgen más dudas o preguntas?
                            </a>
                        </p>
                        <div className="collapse" id="faq5">
                            <div className="card card-body textCustom myCustomFaqAnswer">
                                Puedes comunicarte a través de los canales establecidos.
                                <br />Instagram, Facebook, WhatsApp o Correo electrónico en contacto.
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </animated.div>
    )
}

export default Faq