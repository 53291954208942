import About from "./About";
import Avisos from "./Avisos";
import Banner from "./Banner";
import Blog from "./Blog";
import Contact from "./Contact";
import Faq from "./Faq";
import Services from "./Services";
import Credito from "./Credito";
function LandingPage() {
    return (
        <div>
            <Banner />
            <Services />
            <Blog />
            <Faq />
            <About />
            <Avisos />
            <Contact />
            <Credito />
        </div>
    )
}

export default LandingPage