function BlogCard({ item }) {
const handleBlogLink=(e)=>{
    window.open(e.target.id,"_blank")
}
    return (


        <div className="card h-100 blogCard" id={item.link} onClick={handleBlogLink}>
            <img src={item.image} className="card-img-top h-50 myCustomBlogCard" alt="..." style={{ pointerEvents: "none" }}/>
            <div className="card-body myCustomBlogCard" style={{ pointerEvents: "none" }}>
                <h5 className="card-title">{item.title}</h5>
                <p className="card-text">{item.description}</p>
            </div>
        </div >


    )
}

export default BlogCard