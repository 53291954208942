import aviso1 from "@images/aviso1.png"
import aviso2 from "@images/aviso2.png"
import aviso3 from "@images/aviso3.png"
import avisosTitulo from "@images/letreroAvisosImportantes.png"
import { useInView, animated } from "@react-spring/web"
function Avisos() {
    const [ref,inView]=useInView()
    return (
        <animated.div ref={ref} style={{opacity:inView?1:0,transition:"1.5s ease-in-out"}}>
        <div className="container mt-sm-5 pt-sm-5 avisosContainer" id="avisos">
            <div className="row mt-5">
                <div className="col-sm-2 col-12">
                    <img src={avisosTitulo} className="img-fluid imgAviso" alt="..."  />
                </div>
                <div className="col-1"></div>
                <div className="col-sm-3 col-12">
                    <img src={aviso1} alt="" id="aviso1" />
                </div>
                <div className="col-sm-3 col-12">
                    <img src={aviso2} alt="" id="aviso2"/>
                </div>
                <div className="col-sm-3 col-12">
                    <img src={aviso3} alt="" id="aviso3"/>
                </div>
               
            </div>
        </div>
        </animated.div>
    )
}

export default Avisos