import imagenTerapiaSuperior from "@images/imagenTerapiaSuperior.png"
import imagenTerapiaInferior from "@images/imagenTerapiaInferior.png"
import imagenTerapiaIndividual from "@images/imagenTerapiaIndividual.png"
import imagenTerapiaVincular from "@images/imagenTerapiaVincular.png"
import { HashLink } from "react-router-hash-link"
import { useInView, animated } from "@react-spring/web"
function Therapies() {
    const [ref, springs] = useInView(() => ({
        from: {
            opacity: 0,
            x: "-100%",
        },
        to: {
            opacity: 1,
            x: "0%"
        }
    }))
    const[ref2,springs2]=useInView(() => ({
        from: {
            opacity: 0,
            marginLeft: "50%",
        },
        to: {
            opacity: 1,
            marginLeft: "0%"
        }
    }))
  
    return (
        <div className="container" id="TerapiaIndividual">
            <div className="row terapiaTitle">
                <div className="d-flex col justify-content-center">
                    <h3 className="subtitles">Terapia Psicológica</h3>
                </div>
            </div>
            <animated.div ref={ref} style={{ ...springs, transition: "1s ease-in-out" }}>
                <div className="row">
                    <div className="col-12 col-sm-6" id="imagenterapiasuperior">
                        <img src={imagenTerapiaSuperior} className="img-fluid terapiaImg" alt="..." />
                    </div>
                    <div className="col-12 col-sm-6 align-content-center align-items-center textCustom terapiaText">
                        <p>Aquí encontrarás un espacio individual, como pareja o familia para explorar y abordar dificultades emocionales, mentales, del comportamiento y los vínculos.
                            Los espacios psicológicos permiten comprender nuestros pensamientos, sentimientos y acciones, identificar dificultades en la comunicación y desarrollar habilidades y herramientas para manejar el estrés, mejorar la comunicación y las relaciones; y así, encontrar maneras efectivas de enfrentar los desafíos de la vida, hacer frente a padecimientos en la salud mental o de las relaciones con el entorno.</p>
                    </div>
                </div>
            </animated.div>
            <animated.div ref={ref2} style={{...springs2,transition:"0.3s ease-in-out"}}>
                <div className="row flex-sm-row flex-column-reverse">
                    <div className="col-sm-6 col align-content-center align-items-center">
                        <div className="d-flex col align-items-center textCustom terapiaText">
                            <p>

                                En este espacio podrás abordar aspectos relacionados con la elaboración de vivencias de padecimientos de abuso (físico, psicológico, sexual), ansiedad, depresión, estrés, angustia, duelo prolongado, dificultades en los vínculos y/o entorno, alteraciones en la autopercepción, dificultades en integrar cambios de la vida cotidiana como mudanzas, divorcios, separaciones y/u otras temáticas.
                            </p>
                        </div>
                    </div>
                    <div className="col-sm-6" id="imagenterapiainferior">
                        <img src={imagenTerapiaInferior} className="img-fluid terapiaImg" alt="..." />
                    </div>

                </div>
            </animated.div>
            <div className="row pt-sm-5 mt-sm-5">
                <div className="col-12 col-sm">
                    <div className="row py-3">
                        <div className="col d-flex justify-content-center">
                            <h4 className="subtitles2">Terapia Individual</h4>
                        </div>
                    </div>
                    <div className="row justify-content-center py-4">
                        <img src={imagenTerapiaIndividual} className="img-fluid terapiaImgCard" alt="..." />
                    </div>
                    <div className="col-12 justify-content-center">
                        <div className="row textCustom terapiaText">
                            <div className="col">
                                <p>Es un proceso en el que la persona trabaja de manera directa y personalizada con un profesional abordando desafíos emocionales, cognitivos y conductuales con el objetivo de lograr un grado de bienestar y mejorar la calidad de vida.
                                    <br />Una de las ventajas es la atención personalizada que se brinda a las necesidades únicas de cada persona. El proceso terapéutico se adapta a las situaciones particulares de cada paciente/consultante de manera específica y trabajando hacia las metas individualizadas establecidas.
                                    <br />La terapia individual fomenta un espacio de reflexión y autoexploración profundo que conduce a integrar, transformar y/o fortalecer recursos propios que propicien mejoras en el estado de ánimo, relaciones interpersonales, toma de decisiones y calidad de vida en general.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-1">

                </div>
                <div className="col-12 col-sm">
                    <div className="row py-3">
                        <div className="col-12 d-flex justify-content-center">
                            <h4 className="subtitles2">Terapia Vincular y/o Familiar</h4>
                        </div>
                    </div>
                    <div className="row justify-content-center py-4">
                        <img src={imagenTerapiaVincular} className="img-fluid terapiaImgCard" alt="..." />
                    </div>
                    <div className="row textCustom terapiaText">
                        <p>Este espacio se centra en las dinámicas y relaciones dentro de una familia o sistema vincular, incluyendo parejas, padres, hijos u otras configuraciones familiares.
                            <br /> Se trabaja sobre las problemáticas que influyen en las interacciones familiares, las estructuras de comunicación, patrones de comportamientos y otros aspectos que se desarrollan al interior de los sistemas vinculares; se intenta que se integren los miembros de este sistema.
                            <br />Esta terapia puede ser beneficiosa para una variedad de problemas familiares y relacionales, como conflictos generaciones, problemas de comunicación, dificultades en la crianza, crisis familiares, cambios en la estructura familiar (divorcio, duelo, enfermada crónica) y procesos de adaptación de nuevos miembros.
                        </p>
                    </div>
                </div>
            </div>
            <div className="row mb-2">
                <div className="col d-flex justify-content-end">
                    <span className="align-self-end"><HashLink to="/#finalContact" style={{ textDecoration: "none" }}><a href="/#finalContact"><button type="button" className="btn btn-outline-info btn-sm customBtn myBtn">Escribenos</button></a></HashLink></span>
                </div>
            </div>
        </div >
    )
}

export default Therapies