// import { useEffect } from "react";

import { useEffect } from "react"

function AdminLogin() {
    
      useEffect(()=>{
        const script=document.createElement("script")
        script.src="https://accounts.google.com/gsi/client"
        script.async=true
        script.defer=true
        document.body.appendChild(script)
        return()=>{
            document.body.removeChild(script)
        }
      },[])
    return (
        <div>
            <div id="g_id_onload"
                data-client_id="265820693870-apnpq4ousg645cg4maht77uv0enfipmq.apps.googleusercontent.com"
                data-context="signin"
                data-ux_mode="popup"
                data-login_uri={`${process.env.REACT_APP_API_URL}/api/auth`}
                data-auto_prompt="false">
            </div>

            <div className="g_id_signin"
                data-type="standard"
                data-shape="rectangular"
                data-theme="outline"
                data-text="signin_with"
                data-size="large"
                data-logo_alignment="left">
            </div>
        </div>
    )

}

export default AdminLogin