import cardImgTerapiaPsicologica from "@images/imgTerapiaPsicologica.png"
import cardImgEspacioPadres from "@images/imgEspacioPadres.png"
import cardImgOrientacionProfesionales from "@images/imgOrientacionProfesionales2.png"
import { useInView, animated } from "@react-spring/web"
import { HashLink } from "react-router-hash-link"
import { Link } from "react-router-dom"
function Services() {
    const [ref, inView] = useInView()
    return (
        <animated.div ref={ref} style={{ opacity: inView ? 1 : 0, transition: "1.5s ease-in-out" }}>
            <div className="container py-3">
                <div className="row py-3">
                    <div className="d-flex col justify-content-center subtitles">
                        <p>Servicios</p>
                    </div>
                </div>
                <div className="row d-flex justify-content-center textCustom">
                    <div className=" col-12 col-sm-8 d-flex text-center">
                        <p>En psicología en linea Conecta podrás acceder a 3 tipos de servicios. <br /><br />
                            ➢	Sesiones psicológicas (individuales, pareja o familia).<br />
                            ➢	Orientación a padres, es un espacio de acompañamiento en el proceso de crianza o durante procesos de adopción.<br />
                            ➢	Orientación a profesionales, es un espacio para profesionales que encuentra enriquecedor supervisar casos actuales y/o recibir orientación de profesional a profesional.
                        </p>

                    </div>
                </div>
                <div className="row justify-content-center">
                    <div className="row row-cols-1 row-cols-md-3 g-4">
                        <div className="col d-flex justify-content-center serviceCard">
                            <HashLink to={"/terapiaPsicologica#TerapiaIndividual"} style={{ textDecoration: "none" }}>
                                <div className="card  h-100 cardServices">
                                    <img src={cardImgTerapiaPsicologica} className="card-img-top h-30 myCustomCardTop" alt="..." />
                                    <div className="card-body text-center myCustomCardBottom">
                                        <h5 className="card-title subtitles2">Terapia Psicológica</h5>
                                        <p className="card-text textCustom">Espacio de acompañamiento, contención y/o intervención psicológica por diversas problemáticas de la vida cotidiana y/o relacionadas con padecimientos en salud mental.</p>
                                    </div>
                                </div>
                            </HashLink>
                        </div>
                        <div className="col d-flex justify-content-center serviceCard">
                            <HashLink to={"/orientacionPsicologicaPadres"} style={{ textDecoration: "none" }}>
                                <div className="card h-100 cardServices">
                                    <img src={cardImgEspacioPadres} className="card-img-top h-30 myCustomCardTop" alt="..." />
                                    <div className="card-body text-center myCustomCardBottom">
                                        <h5 className="card-title subtitles2">Orientación a Padres</h5>
                                        <p className="card-text textCustom">
                                            Este espacio está diseñado para acompañar y orientar a padres en el desarrollo de sus hijos y los desafíos que esto presenta; tanto en la niñez como en la adolescencia.
                                            También acompaña la integración del núcleo familiar en procesos de adopción o guardas judiciales.

                                        </p>
                                    </div>
                                </div>
                            </HashLink>
                        </div>
                        <div className="col d-flex justify-content-center serviceCard">
                            <Link to={"/orientacionProfesionalPsicologia"} style={{ textDecoration: "none" }}>
                                <div className="card  h-100 cardServices">
                                    <img src={cardImgOrientacionProfesionales} className="card-img-top h-30 myCustomCardTop" alt="..." />
                                    <div className="card-body text-center myCustomCardBottom">
                                        <h5 className="card-title subtitles2">Orientación a Profesionales</h5>
                                        <p className="card-text textCustom">Espacio para profesionales psicólogos que quieran o necesitan generar un intercambio profesional respecto a una situación específica que tengan en la práctica profesional y les resulte beneficioso adquirir o intercambiar perspectivas.</p>
                                    </div>
                                </div>
                            </Link>
                        </div>

                    </div>
                </div>

            </div>
        </animated.div>
    )
}

export default Services