import { useState, useRef } from "react";
import DatePicker from "react-datepicker"
import ReCAPTCHA from "react-google-recaptcha"
import { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css"
import Swal from "sweetalert2"
import setHours from "date-fns/setHours"
import setMinutes from "date-fns/setMinutes"
import addDays from "date-fns/addDays"
import subDays from "date-fns/subDays"
import es from "date-fns/locale/es"
registerLocale("es", es)
let timeWindow = []
let minuteOff
let hourOffMin
let hourOffMax



const Example = ({ cOffset, timeZone }) => {
  const recaptcha1 = useRef()

  let allTimesAvailable = [
    setHours(setMinutes(new Date(), 0), 0),
    setHours(setMinutes(new Date(), 30), 0),
    setHours(setMinutes(new Date(), 0), 1),
    setHours(setMinutes(new Date(), 30), 1),
    setHours(setMinutes(new Date(), 0), 2),
    setHours(setMinutes(new Date(), 30), 2),
    setHours(setMinutes(new Date(), 0), 3),
    setHours(setMinutes(new Date(), 30), 3),
    setHours(setMinutes(new Date(), 0), 4),
    setHours(setMinutes(new Date(), 30), 4),
    setHours(setMinutes(new Date(), 0), 5),
    setHours(setMinutes(new Date(), 30), 5),
    setHours(setMinutes(new Date(), 0), 6),
    setHours(setMinutes(new Date(), 30), 6),
    setHours(setMinutes(new Date(), 0), 7),
    setHours(setMinutes(new Date(), 30), 7),
    setHours(setMinutes(new Date(), 0), 8),
    setHours(setMinutes(new Date(), 30), 8),
    setHours(setMinutes(new Date(), 0), 9),
    setHours(setMinutes(new Date(), 30), 9),
    setHours(setMinutes(new Date(), 0), 10),
    setHours(setMinutes(new Date(), 30), 10),
    setHours(setMinutes(new Date(), 0), 11),
    setHours(setMinutes(new Date(), 30), 11),
    setHours(setMinutes(new Date(), 0), 12),
    setHours(setMinutes(new Date(), 30), 12),
    setHours(setMinutes(new Date(), 0), 13),
    setHours(setMinutes(new Date(), 30), 13),
    setHours(setMinutes(new Date(), 0), 14),
    setHours(setMinutes(new Date(), 30), 14),
    setHours(setMinutes(new Date(), 0), 15),
    setHours(setMinutes(new Date(), 30), 15),
    setHours(setMinutes(new Date(), 0), 16),
    setHours(setMinutes(new Date(), 30), 16),
    setHours(setMinutes(new Date(), 0), 17),
    setHours(setMinutes(new Date(), 30), 17),
    setHours(setMinutes(new Date(), 0), 18),
    setHours(setMinutes(new Date(), 30), 18),
    setHours(setMinutes(new Date(), 0), 19),
    setHours(setMinutes(new Date(), 30), 19),
    setHours(setMinutes(new Date(), 0), 20),
    setHours(setMinutes(new Date(), 30), 20),
    setHours(setMinutes(new Date(), 0), 21),
    setHours(setMinutes(new Date(), 30), 21),
    setHours(setMinutes(new Date(), 0), 22),
    setHours(setMinutes(new Date(), 30), 22),
    setHours(setMinutes(new Date(), 0), 23),
    setHours(setMinutes(new Date(), 30), 23)
  ]
  const [startDate, setStartDate] = useState(null)
  const [nombre, setNombre] = useState("")
  const [email, setEmail] = useState("")
  const [tel, setTel] = useState("")
  const [pais, setPais] = useState("")
  const [excludeTimesAp, setExcludeTimesAp] = useState(allTimesAvailable)
  const [fechaSeleccionada, setFechaSeleccionada] = useState({})
  const [citas, setCitas] = useState([])
  let fExcluida = []


  const formatExcludeAppoint = async (date) => {
    let diaSeleccionado = date.getDate()
    let mesSeleccionado = date.getMonth() + 1
    let busqueda = diaSeleccionado + "/" + mesSeleccionado
    let regex = new RegExp(busqueda)
    let testCitas = citas.filter(e => {
      return regex.test(e)
    })
    if (testCitas.length !== 0) {
      testCitas.forEach((doc) => {
        let tempMin
        let tempHour
        tempMin = doc.split(/[/]+/)[2].split(/[,]+/)[1].split(/[:]/)[1]
        tempHour = doc.split(/[/]+/)[2].split(/[,]+/)[1].split(/[:]/)[0]
        fExcluida.push(setHours(setMinutes(new Date(), tempMin), tempHour))
      })
    }
    setExcludeTimesAp(fExcluida)
  }
  const apiCallerCitas = async (date, timeZone) => {
    let month = date.getMonth() + 1
    let searchParams = new URLSearchParams({
      "timezone": timeZone,
      "month": month
    })
    console.log(searchParams)
    const result = await fetch(`${process.env.REACT_APP_API_URL}/api/citas?${searchParams}`)
    const response = await result.json()
    return response
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    const captchaValue1 = recaptcha1.current.getValue()
    const body = {}
    body.cOffset = cOffset
    body.timezone = timeZone
    body.utcTime = fechaSeleccionada.toISOString() //prueba para ver el formato de l fecha y hora
    body.fecha = startDate.toLocaleString(`es-ES`)
    body.nombre = nombre
    body.email = email
    body.tel = tel
    body.pais = pais
    if (!captchaValue1) {
      Swal.fire("Verifica que eres humano")
    } else {
      const { value: accept } = await Swal.fire({
        title: "Terminos y condiciones",
        input: "checkbox",
        inputValue: 0,
        inputPlaceholder: `Acepto los <a href="/assets/documents/TerminosYCondiciones.pdf" target="_blank">terminos y condiciones</a> y las <a href="/assets/documents//PoliticaDePrivacidad.pdf" target="_blank">politicas de privacidad</a>`,
        confirmButtonText: "Continuar",
        customClass: {
          confirmButton: "myBtnSwal"
        },
        showCancelButton: true,
        inputValidator: (result) => {
          return !result && "Debes aceptar los terminos y condiciones para continuar"
        }

      })
      if (accept) {
        const res = await fetch(`${process.env.REACT_APP_API_URL}/api/email`, {
          body: JSON.stringify(body),
          method: "POST",
          headers: {
            "Content-Type": "application/json"
          }
        })
        const data = await res.json()
        alert(data.msg)
        window.location.reload()
      } else {
        console.log("no acepte")
      }

    }
  }

  const isWeekday = (date) => {
    const day = date.getDay()
    return day !== 0 && day !== 6
  }


  if ((cOffset / 3600) % 1 === 0) {
    minuteOff = 1
  }
  if (cOffset <= -18000) {
    hourOffMin = 10 + (18000 + cOffset) / 3600
    hourOffMax = 19 + (18000 + cOffset) / 3600
  } else {
    hourOffMin = 10 + Math.trunc((18000 + cOffset) / 3600)
    hourOffMax = 19 + Math.trunc((18000 + cOffset) / 3600)

  }
  if (hourOffMax >= 24) {
    let tempWindow1
    let tempWindow2
    hourOffMax = hourOffMax - 24
    tempWindow1 = allTimesAvailable.slice(0, hourOffMax * 2 + 2)
    tempWindow2 = allTimesAvailable.slice(hourOffMin * 2, 48)
    if (minuteOff === 1 && hourOffMin === 25) {
      tempWindow1 = allTimesAvailable.slice(1, hourOffMax * 2 + 3)
    }
    timeWindow = tempWindow1.concat(tempWindow2)
  } else {
    timeWindow = allTimesAvailable.slice(hourOffMin * 2, hourOffMax * 2 + 2)
  }

  const test = async (date, timeZone) => {
    apiCallerCitas(date, timeZone).then(response => {
      setCitas([...response])
    })
    formatExcludeAppoint(date)

  }




  return (
    <>
      <DatePicker
        showIcon
        selected={startDate}
        onChange={(date) => {
          test(date, timeZone)
          setStartDate(date)
          setFechaSeleccionada(date)
        }}
        onCalendarOpen={() => {
          test(new Date(), timeZone)
          setFechaSeleccionada(new Date())
        }}
        showTimeSelect
        filterDate={isWeekday}
        includeTimes={timeWindow}
        excludeTimes={excludeTimesAp}
        excludeDateIntervals={[
          { start: subDays(new Date(), 10), end: addDays(new Date(), 1) },
        ]}
        locale={es}
        dateFormat="MMMM d, yyyy h:mm aa"
        required
        form="external-form"
      />
      <form id="external-form" onSubmit={handleSubmit}>
        <input type="text" className="form-control" id="nombre" placeholder="Nombre y Apellido" value={nombre} onChange={(e) => setNombre(e.target.value)} required />
        <input type="text" className="form-control" id="email" placeholder="Correo Electrónico" value={email} onChange={(e) => setEmail(e.target.value)} required />
        <input type="text" className="form-control" id="tel" placeholder="Teléfono/Whatsapp" value={tel} onChange={(e) => setTel(e.target.value)} required />
        <input type="text" className="form-control" id="pais" placeholder="País" value={pais} onChange={(e) => setPais(e.target.value)} required />
        <div className="row justify-content-center my-3">
          <div className="col">
            <button type="submit" className="btn btn-outline-info btn-sm customBtn myBtn">Enviar</button>
          </div>
        </div>
        <div className="col d-flex justify-content-start">
          <ReCAPTCHA ref={recaptcha1} sitekey={process.env.REACT_APP_SITE_KEY} />
        </div>
      </form>
    </>
  );
};

export default Example