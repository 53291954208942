import { useEffect, useState } from "react"
import Swal from "sweetalert2"
import BlogCard from "./BlogCard"
function BlogPageAdmin() {
    let [items, setItems] = useState([])
    useEffect(() => {
        fetch(`${process.env.REACT_APP_API_URL}/api/blog`)
            .then(data => {
                return data.json()
            })
            .then(post => {
                setItems(post.data.docs)
                console.log(post.data.docs)
            })
    }, [])

    const handleSubmitDelete = (e) => {
        console.log(e.target.id)
        console.log(e.target.name)
        let mongoId = e.target.id
        let imageId = e.target.name
        let body = {
            mongoId,
            imageId
        }
        body = JSON.stringify(body)

         fetch(`${process.env.REACT_APP_API_URL}/api/blog`,{body,
             method:"DELETE",
             headers: {
                 "Content-Type": "application/json"
               }
             
         })
         .finally(()=>{
            Swal.fire("Entrada Eliminada")
            window.location.replace("https://psicologiaenlineaconecta.com/blogpageadmin")
         })
    }

    return (
        <div className="container">
            <div className="row my-5">
                {items.map(item => (
                    <div className="col-md-4 col-sm-12 my-5" key={item.title}>
                        <BlogCard item={item} />
                        <div key={item.title + item.id}>
                            <button type="submit" id={item._id} name={item.image} className="btn btn-primary mb-3" onClick={handleSubmitDelete}>Eliminar</button>

                        </div>
                    </div>

                ))}
            </div>
        </div>
    )
}

export default BlogPageAdmin