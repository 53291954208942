function Credito() {
    return (
        <div className="container" id="credito">
            <div className="row">
                <div className="col">
                    
                       <span className="myCustomCredito"> Fondo Web de kjpargeter en Freepik</span> <br />
                    
                </div>
            </div>
        </div>
    )
}

export default Credito