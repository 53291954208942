import { BrowserRouter, Routes, Route, HashRouter} from "react-router-dom"
import Footer from "./components/Footer";
import Header from "./components/Header";
import NavBar from "./components/NavBar"
import './index.css';
import LandingPage from "./components/LandingPage";
import Therapies from "./components/Therapies";
import Orientation from "./components/Orientation";
import Supervision from "./components/Supervision";
import BlogUpload from "./components/BlogUpload";
import BlogPage from "./components/BlogPage";
import BlogPageAdmin from "./components/BlogPageAdmin";
import AdminLogin from "./components/AdminLogin";
import Protected from "./components/Protected";



function App() {

  return (
    <BrowserRouter>
      <Header />
      <NavBar />
      <Routes>
        <Route path={"/"} element={<LandingPage />} />
        <Route path={"/terapiaPsicologica"} element={<Therapies />} />
        <Route path={"/orientacionPsicologicaPadres"} element={<Orientation />} />
        <Route path={"/orientacionProfesionalPsicologia"} element={<Supervision />} />
        <Route path={"/blogPage"} element={<BlogPage />} />
        <Route element={<Protected/>}>
          <Route path={"/blogupload"} element={<BlogUpload />} />
          <Route path={"/blogpageAdmin"} element={<BlogPageAdmin />} />
        </Route>
        <Route path={"/login"} element={<AdminLogin />} />
      </Routes>
      <Footer />
    </BrowserRouter>
  );
}

export default App;
