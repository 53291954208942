import imgBlogTitulo from "@images/imgBlogTitulo.png"
import BlogCard from "./BlogCard"
import { Link } from "react-router-dom"
import { useInView, animated } from "@react-spring/web"
import { useEffect, useState } from "react"
import axios from "axios"
function Blog() {
    const URL_API = process.env.REACT_APP_API_URL
    const [ref, inView] = useInView()
    const [blogMain, setBlogMain] = useState([])
    useEffect(() => {
        axios.get(`${URL_API}/api/blog?limit=3&sort=desc`)
            .then((res) => {
                console.log(res)
                setBlogMain(res.data.data.docs)
            })
    }, [])
    return (
        <animated.div ref={ref} style={{ opacity: inView ? 1 : 0, transition: "1.5s ease-in-out" }}>
            <div className="container py-3" id="blog">
                <div className="row py-3">
                    <div className="d-flex col justify-content-center subtitles">
                        <img src={imgBlogTitulo} alt="" className="imgBlogTitulo" />

                    </div>
                </div>
                <div className="row d-flex justify-content-center">
                    <div className="col-sm-8 d-flex text-center textCustom">
                        <p>Encontrarás información de diferentes temáticas vinculadas a la salud mental y la psicología; esta información será presentada desde diferentes enfoques de abordaje en salud mental.</p>
                    </div>
                </div>
                <div className="row justify-content-center">
                    <div className="row row-cols-1 row-cols-md-3 g-4">
                        {blogMain.map((e) => (
                            <div className="col d-flex justify-content-center" key={e.title}>
                                <BlogCard item={e} />
                            </div>
                            /*  <div className="col d-flex justify-content-center">
                                 <div className="card h-100 blogCard">
                                     <img src={e.image} className="card-img-top h-50 myCustomBlogCard" alt="..." />
                                     <div className="card-body myCustomBlogCard">
                                         <h5 className="card-title">{e.title}</h5>
                                         <p className="card-text">{e.description}</p>
                                     </div>
                                 </div>
                             </div> */
                        ))}
                    </div>
                </div>
                <div className="row py-3">
                    <div className="col d-flex justify-content-end">
                        <Link to={"/blogpage"} className="text-decoration-none"><button type="button" className="btn btn-outline-info btn-sm customBtn myBtn">Leer Mas...</button></Link>
                    </div>
                </div>
            </div>
        </animated.div>
    )
}

export default Blog