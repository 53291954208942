import { useEffect, useState } from "react"
import BlogCard from "./BlogCard"
import imgBlog from "@images/imgBlog.png"
import axios from "axios"
function BlogPage() {
    let [items, setItems] = useState([])
    let [paginationData, setPaginationData] = useState()
    let [pages, setPages] = useState()
    let [category, setCategory] = useState([])
    const URL_API = process.env.REACT_APP_API_URL
    useEffect(() => {
        axios.get(`${URL_API}/api/blog?sort=desc`)
            .then((res) => {
                setItems(res.data.data.docs)
                setPaginationData(res.data.data)
                let tempPages = buildPagination(res)
                setPages(tempPages)
            })
        axios.get(`${URL_API}/api/blogcategory`)
            .then((res) => {
                setCategory(res.data.result)
            })
    }, [])

    const buildPagination = (res) => {
        let tempPages = []
        for (let i = 1; i <= res.data.data.totalPages; i++) {
            if (res.data.data.page === i) {
                tempPages.push(<li className="page-item active" id={i} key={i}><button className="page-link">{i}</button></li>)
            } else {
                tempPages.push(<li className="page-item" key={i}><button id={i} className="page-link" onClick={handleNavigation}>{i}</button></li>)
            }
        }
        return tempPages
    }
    const handleNavigation = (e) => {
        switch (e.target.id) {
            case "next":
                let nextPage = paginationData.nextPage
                axios.get(`${URL_API}/api/blog?page=${nextPage}&sort=desc`)
                    .then((res) => {
                        setItems(res.data.data.docs)
                        setPaginationData(res.data.data)
                        let tempPages = buildPagination(res)
                        setPages(tempPages)
                    })
                break
            case "prev":
                let prevPage = paginationData.prevPage
                axios.get(`${URL_API}/api/blog?page=${prevPage}&sort=desc`)
                    .then((res) => {
                        setItems(res.data.data.docs)
                        setPaginationData(res.data.data)
                        let tempPages = buildPagination(res)
                        setPages(tempPages)
                    })
                break
            default:
                axios.get(`${URL_API}/api/blog?page=${e.target.id}&sort=desc`)
                    .then((res) => {
                        setItems(res.data.data.docs)
                        setPaginationData(res.data.data)
                        let tempPages = buildPagination(res)
                        setPages(tempPages)
                    })
                break
        }

    }
    const handleSearchCategory=(e)=>{
        let option=e.target.getAttribute("tag")
        axios.get(`${URL_API}/api/blogsearch?${option}=${e.target.id}&sort=desc`)
                    .then((res) => {
                        setItems(res.data.data.docs)
                        setPaginationData(res.data.data)
                        let tempPages = buildPagination(res)
                        setPages(tempPages)
                    })
    }
    return (
        <div className="container-fluid">
            <div className="row">
                <div className="col d-flex justify-content-center my-5 subtitles">
                    <h1>Información</h1>
                    <img src={imgBlog} alt="" style={{ width: "8%" }} />
                </div>
            </div>
            <div className="row">
                <div className="col2">
                    <nav className="navbar navbar-left">
                        <button className="navbar-toggler" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasNavbar" aria-controls="offcanvasNavbar" aria-label="Toggle navigation">
                            <span className="navbar-toggler-icon customToggler"></span><span className="menuCategoria">Buscar</span>
                        </button>
                        <div className="offcanvas offcanvas-start" tabIndex="-1" id="offcanvasNavbar" aria-labelledby="offcanvasNavbarLabel">
                            <div className="offcanvas-header">
                            <button className="btn" type="button" data-bs-toggle="collapse" data-bs-target="#collapseExample" aria-expanded="false" aria-controls="collapseExample">
                            <p className="textCustom menuCategoria">Categorias&raquo;</p>
                        </button>
                                <button type="button" className="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                            </div>
                            <div className="collapse" id="collapseExample" style={{ marginTop: "-10%" }}>
                                <div className="card card-body" style={{ backgroundColor: "transparent", border: "none" }}>
                                    <ul className="list-group">
                                        {category.map(item => (
                                            <li className="list-group-item categoryList" style={{ backgroundColor: "transparent" }} tag={"categoria"} onClick={handleSearchCategory} id={item} key={item}>{item}</li>
                                        ))}
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </nav>
                </div>
                <div className="col-1">

                </div>
                <div className="col">
                    <div className="row justify-content-center my-5">
                        {items.map(item => (
                            <div className="col-md-4 col-sm-12 my-3" key={item.title}>
                                <BlogCard item={item} />
                            </div>
                        ))}
                    </div>
                    {paginationData ?
                        <div className="row justify-content-center">
                            <div className="col-md-2 col-sm-12">
                                <nav aria-label="Page navigation example">
                                    <ul className="pagination">
                                        <li className={`page-item ${paginationData.hasPrevPage ? "" : "disabled"}`}>
                                            <button className="page-link" id="prev" aria-label="Previous" onClick={handleNavigation}>
                                                &laquo;
                                            </button>
                                        </li>
                                        {pages}
                                        <li className={`page-item ${paginationData.hasNextPage ? "" : "disabled"}`}>
                                            <button className="page-link" id="next" aria-label="next" onClick={handleNavigation}>
                                                &raquo;
                                            </button>
                                        </li>
                                    </ul>
                                </nav>
                            </div>
                        </div> : null
                    }
                </div>
            </div >

        </div >
    )
}

export default BlogPage