import image16 from "../components/images/16.png"
import { useInView, animated } from "@react-spring/web"
import { HashLink } from "react-router-hash-link"
import Lottie from "lottie-react"
import animationData from "./images/brain.json"

function Banner() {
    const [ref, inView] = useInView()
    return (
        <animated.div ref={ref} style={{ opacity: inView ? 1 : 0, transition: "1.5s ease-in-out" }}>
            <div className="container py-3 textCustom">
                <div className="row py-3 flex-sm-row flex-column-reverse">
                    <div className="col-sm-8" style={{ position: "relative" }}>
                        <div id="bannerAnimation" style={{ zIndex: -5 }}>
                            <Lottie animationData={animationData} loop="false" />

                        </div>
                        <p className="parrafoBanner">Psicología en línea Conecta es un espacio de consultorio psicológico en modalidad virtual en español, sin importar el país en donde estés.
                            En este espacio podrás trabajar en ti y buscar bienestar acompañado por profesionales de psicológica con años de experiencia en la atención clínica. Sesión a sesión buscaremos las causas de tu malestar y/o padecimiento; cómo se manifiesta; comprender esa reacción y generar herramientas y análisis que puedas implementar para abordarlo.
                            También contamos con espacio de acompañamiento a padres y profesionales, te invitamos a seguir explorando nuestra web.
                            <br /><br />
                            La modalidad virtual permite tener flexibilidad y adaptabilidad, puesto que podrás continuar con tu espacio aun si te encuentras en viajes temporales, traslados permanentes, posees dificultades para realizar desplazamiento o simplemente porque la modalidad virtual te resulta más beneficiosa en tu organización cotidiana.
                            <br /><br />
                            Permítete implementar cambios a nivel personal y/o profesional, buscar acompañamiento y date la oportunidad de transformar y resignificar.

                        </p> <br />
                        <HashLink to="/#contact" style={{ textDecoration: "none" }}><button type="button" className="btn btn-outline-info btn-sm customBtn myBtn">¡ Quiero Contactarme !</button></HashLink>
                    </div>
                    <div className="col-sm-4">
                        <div className="row pb-2">
                            <div className="col d-flex justify-content-center">
                                <img src={image16} className="img-fluid imgBanner" alt="..." />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </animated.div>

    )
}

export default Banner