import instagramIcon from "@images/inst.ico"
import faceIcon from "@images/face.ico"
import whatsIcon from "@images/whatsIcon.png"
import Example from "./Calendar"
import { useEffect, useRef, useState } from "react"
import ReCAPTCHA from "react-google-recaptcha"
import { useInView, animated } from "@react-spring/web"
import Swal from "sweetalert2"
function Contact() {
    const recaptcha = useRef()
    let [Timeout, setTimeout] = useState(0)
    let [timezone, setTimezone] = useState("")
    let [emailContacto, setEmailContacto] = useState("")
    let [telContacto, setTelContacto] = useState("")
    let [mensajeContacto, setMensajeContacto] = useState("")
    let [nombreContacto, setNombreContacto] = useState("")
    const offset = Intl.DateTimeFormat().resolvedOptions().timeZone
    const [ref,inView]=useInView()
    const timeApi = "https://worldtimeapi.org/api/timezone/" + offset

    useEffect(() => {
        fetch(`${timeApi}`)
            .then(data => {
                return data.json()
            })
            .then(post => {
                console.log(post)
                // setTimeout((post.dst_offset+post.raw_offset)/60/60)
                setTimeout(post.dst_offset + post.raw_offset)
                setTimezone(post.timezone)

            })
    }, [timeApi])

    const handleSubmitMessage = async (e) => {
        e.preventDefault()
        const captchaValue = recaptcha.current.getValue()
        const body = {}
        body.email = emailContacto
        body.tel = telContacto
        body.mensaje = mensajeContacto
        body.captchaValue = captchaValue
        body.nombre = nombreContacto
        if (!captchaValue) {
           Swal.fire("Verifica que eres humano")
        } else {
            const {value:accept}=await Swal.fire({
                title:"Terminos y condiciones",
                input:"checkbox",
                inputValue:0,
                inputPlaceholder:`Acepto los <a href="/assets/documents/TerminosYCondiciones.pdf" target="_blank">terminos y condiciones</a> y las <a href="/assets/documents//PoliticaDePrivacidad.pdf" target="_blank">politicas de privacidad</a>`,
                confirmButtonText:"Continuar",
                customClass:{
                    confirmButton:"myBtnSwal"
                },
                showCancelButton:true,
                inputValidator:(result)=>{
                    return !result&&"Debes aceptar los terminos y condiciones para continuar"
                }

            })
            if(accept){
                const res = await fetch(`${process.env.REACT_APP_API_URL}/api/emailContacto`, {
                    body: JSON.stringify(body),
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json"
                    }
                })
                const data = await res.json()
                if (data.captcha) {
                    Swal.fire("Mensaje enviado")
                    setEmailContacto("")
                    setTelContacto("")
                    setMensajeContacto("")
                    setNombreContacto("")
                } else {
                    alert("error al enviar el mensaje, verifique los datos ingresados")
                }
            }else{
                console.log("no acepte")
            }
            
            
        }

    }
    

    return (
        <animated.div ref={ref} style={{opacity:inView?1:0,transition:"1.5s ease-in-out"}}>
        <div className="container" id="contact">
            <div className="row py-3">
                <div className="d-flex col justify-content-center subtitles">
                    <p>Contacto</p>
                </div>
            </div>
            <div className="container">
                <div className="row">
                    <div className="col-sm-4" id="citasContacto">
                        <div className="row my-2">
                            <div className="col my-2">
                                <div className="row contactoSubtitles">
                                    <h5 className="subtitles2">Agendamiento primer contacto</h5>
                                </div>
                                <div className="row mx-1 contactoCita">
                                    <br />Podrás agendar un primer encuentro, el cual será informativo, NO es una sesión psicológica. <br />El mismo se realiza a través de una videollamada de 15 minutos aproximadamente en donde se explica la modalidad online, se resuelven dudas, se conoce el motivo de consulta y se evalúa la pertinencia de la modalidad virtual dependiendo de la problemática.
                                    <br />Luego de ese encuentro, se agendará la primera sesión psicológica.
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col my-1">
                            <a href="https://www.instagram.com/psicologia.en.linea.conecta?igsh=ZTVkdThybWswYTdo" target="_blank" rel="noopener noreferrer"><img src={instagramIcon} className="iconSizeContact mx-2" alt="icono instagram" /></a>
                            <a href="https://www.facebook.com/share/hptgygsXEi7VG9sS/?mibextid=qi2Omg" target="_blank" rel="noopener noreferrer"><img src={faceIcon} className="iconSizeContact" alt="icono facebook" /></a>
                            <a href="https://wa.me/573185024375" target="_blank" rel="noopener noreferrer"><img src={whatsIcon} className="iconSizeContact mx-2" alt="icono whatsApp" /></a>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col">
                                <p>contacto@psicologiaenlineaconecta.com</p>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col">
                                <Example cOffset={Timeout} timeZone={timezone} />
                            </div>
                        </div>
                    </div>

                    <div className="col-sm-8" id="contactoContacto">
                        <div className="row my-2 contactoSubtitles">
                            <div className="col my-2">
                                <h5 className="subtitles2">Consultas Mail</h5>
                            </div>
                        </div>
                        <div className="row my-3">
                            <div className="col">
                                Puedes dejarnos tus preguntas, dudas y consultas. Te las responderemos a la mayor brevedad posible.
                            </div>
                        </div>
                        <div className="row">
                            <form id="mensajeContacto" onSubmit={handleSubmitMessage}>
                                <div className="mb-3">
                                    <label htmlFor="exampleFormControlInput1.1" className="form-label">Nombre</label>
                                    <input type="text" className="form-control" id="nombreContacto" placeholder="Nombre y Apellido" value={nombreContacto} onChange={(e) => setNombreContacto(e.target.value)} required />
                                    <label htmlFor="exampleFormControlInput1" className="form-label">Correo Electrónico</label>
                                    <input type="email" className="form-control" id="emailContacto" placeholder="nombre@ejemplo.com" value={emailContacto} onChange={(e) => setEmailContacto(e.target.value)} required />
                                    <label htmlFor="exampleFormControlInput2" className="form-label">Teléfono (opcional)</label>
                                    <input type="phone" className="form-control" id="telContacto" placeholder="+573155445655" value={telContacto} onChange={(e) => setTelContacto(e.target.value)} />
                                </div>
                                <div className="mb-5">
                                    <label htmlFor="exampleFormControlTextarea1" className="form-label">Mensaje</label>
                                    <textarea className="form-control" id="mensajeContacto" rows="3" value={mensajeContacto} onChange={(e) => setMensajeContacto(e.target.value)} required></textarea>
                                </div>
                                <div className="mb-3">
                                    <div className="row">
                                        <div className="col-2 d-flex ">
                                            <button type="submit" className="btn btn-outline-info btn-sm customBtn myBtn">Enviar</button>
                                        </div>
                                        <div className="col d-flex justify-content-start">
                                            <ReCAPTCHA ref={recaptcha} sitekey={process.env.REACT_APP_SITE_KEY} />
                                        </div>
                                    </div>


                                </div>
                            </form>
                        </div>
                        <div className="row" id="finalContact">
                     
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </animated.div>
    )
}

export default Contact