import { useEffect, useState } from "react"
import { HashLink } from "react-router-hash-link"
import { Link } from "react-router-dom"
function NavBar() {
    
    const[screenSize,setScreenSize]=useState(getCurrentDimension())
    function getCurrentDimension(){
        if (window.innerWidth>1000){
            return true
        }
        else{
            return false
        }
    }

    useEffect(()=>{
        const updateDimension=()=>{
            setScreenSize(getCurrentDimension())
        }
        window.addEventListener("resize",updateDimension)
        return(()=>{
            window.removeEventListener("resize",updateDimension)
        })
    },[screenSize])
    
    return (
        <nav className="navbar navbar-expand-lg sticky-top subtitles2 navbarStyle">
            
            <div className="container-fluid">
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse  justify-content-center" id="navbarNavDropdown">
                    <ul className={`navbar-nav ${screenSize?"d-inline-block container-fluid":""}`} id="navBar">
                        <div className={`${screenSize?"d-flex justify-content-around":""}`}>
                            <li className="nav-item">
                               <HashLink to="/#inicio" style={{textDecoration:"none"}}> <a className="nav-link navBarLi" aria-current="page" href="/">Inicio</a></HashLink>
                            </li>
                            <li className="nav-item">
                                <HashLink to="/#about" style={{textDecoration:"none"}}><a className="nav-link navBarLi" aria-current="page" href="/#about">Acerca de Nosotros</a></HashLink>
                            </li>
                            <li className="nav-item dropdown">
                                <a className="nav-link dropdown-toggle navBarLi" href="/" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                    Servicios
                                </a>
                                <ul className="dropdown-menu">
                                    <li><Link to={"/terapiaPsicologica"} className="dropdown-item">Terapia Psicológica</Link></li>
                                    <li><Link to={"/orientacionPsicologicaPadres"} className="dropdown-item">Orientación a Padres</Link></li>
                                    <li><Link to={"/orientacionProfesionalPsicologia"} className="dropdown-item">Orientación a Profesionales</Link></li>
                                </ul>
                            </li>
                            <li className="nav-item">
                            <HashLink to="/#faq" style={{textDecoration:"none"}}><a className="nav-link navBarLi" href="/#faq">Preguntas Frecuentes</a></HashLink>
                            </li>
                            <li className="nav-item">
                                <HashLink to="/#blog" style={{textDecoration:"none"}}> <a className="nav-link navBarLi" href="/#blog">Blog</a></HashLink>
                            </li>
                            <li className="nav-item">
                                <HashLink to="/#avisos" style={{textDecoration:"none"}}> <a className="nav-link navBarLi" href="/#contact">Contáctanos</a></HashLink>
                            </li>
                        </div>
                    </ul>
                </div>
            </div>
        </nav>
    )
}

export default NavBar