import cardImg from "@images/Supervision1.png"
import { HashLink } from "react-router-hash-link"
import { useSpring, useInView, animated } from "@react-spring/web"
function Supervision() {
    const [ref, springs] = useInView(() => ({
        from: {
            opacity: 0,

        },
        to: {
            opacity: 1,

        }
    }))
    return (
        <div className="container" id="Supervision">
            <div className="row py-5">
                <div className="d-flex col justify-content-center subtitles text-nowrap">
                    <h3>Orientación a Profesionales</h3>
                </div>
            </div>
            <animated.div ref={ref} style={{ ...springs, transition: `1s ease` }}>
                <div className="row pb-5">
                    <div className="clearfix SupervisionProf">
                        <img src={cardImg} className="col-md-6 float-md-start mb-3 me-md-3 orientacionProfImg" alt="..." />

                        <p className="textCustom">
                            <br /><br />
                            En este espacio se brinda orientación, apoyo e intercambio a otro psicológico en su práctica en el ámbito clínico, discutir las estrategias de intervención, generar espacios de retroalimentación respecto a la práctica y abordar preguntas e inquietudes que puedan surgir.
                            <br />
                            <br />
                            Es un espacio creado desde la empatía, entendiendo que todos los que ejercemos desde la práctica clínica hemos necesitado apoyo, ampliar perspectivas, crear y pensar respecto de otras herramientas, y por esto este espacio brinda apoyo, acompañamiento y contención en la práctica, generando contrastes en momentos de duda o atasco.

                            Así como, repensar aspectos relacionados con el desarrollo profesional.
                            <br /><br />
                            <div className="row">
                                <div className="col d-flex justify-content-end">
                                    <span className="align-self-end"><HashLink to="/" elementId="avisos" style={{ textDecoration: "none" }}><button type="button" className="btn btn-outline-info btn-sm customBtn myBtn">Escribenos</button></HashLink></span>
                                </div>
                            </div>

                        </p>

                    </div>
                </div>
            </animated.div>
        </div>
    )
}

export default Supervision