// import logo from "@images/logo.png"
import instagramIcon from "@images/inst.ico"
import faceIcon from "@images/face.ico"
import whatsIcon from "@images/whatsIcon.png"
import logoV2 from "@images/mainLogo.png"
function Header() {
    return (
        <div className="container-fluid"  id="inicio">
            <div className="row align-items-center my-2 justify-content-center">
                <div className="col-md-6 d-flex justify-content-center">
                    <div className="container">
                        <div className="row headerRow">
                            <div className="col">
                                <img src={logoV2} id="logoImg" className="rounded mx-auto d-block img-fluid" alt="logo psicologia en linea"/>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col text-center" id="bannerHeader">
                                <p className="lauraCespedesHeader">PSICOLOGÍA EN LÍNEA CONECTA</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row my-3 justify-content-sm-end">
                <div className="col-12 d-flex justify-content-around col-sm-4 justify-content-sm-end">
                    <a href="https://www.instagram.com/psicologia.en.linea.conecta?igsh=ZTVkdThybWswYTdo" target="_blank" rel="noopener noreferrer"><img src={instagramIcon} className="mx-1 iconHeader" alt="icono instagram" /></a>
                    <a href="https://www.facebook.com/share/hptgygsXEi7VG9sS/?mibextid=qi2Omg" target="_blank" rel="noopener noreferrer"><img src={faceIcon} className="iconHeaderFace" alt="icono facebook" /></a>
                    <a href="https://wa.me/573185024375" target="_blank" rel="noopener noreferrer"><img src={whatsIcon} className="mx-1 iconHeader" alt="icono whatsApp" /></a>
                </div>
            </div>
        </div>


    )
}

export default Header