import logo from "@images/logo.png"
import instagramIcon from "@images/inst.ico"
import faceIcon from "@images/face.ico"
import whatsIcon from "@images/whatsIcon.png"
function Footer() {
    return (
        <div className="container-fluid" id="footer">
            <div className="row footerRow">
                <div className="col d-flex justify-content-center d-xl-block d-none">
                    <img src={logo} id="logoImgFooter" className="rounded mx-auto d-block img-fluid" alt="logo psicologia en linea" />
                </div>
                <div className="col-4 d-flex justify-content-end align-items-center align-middle">
                    <span className="footerDevSign">® Developed by SaloIT</span>
                </div>
                <div className="col-4 d-flex justify-content-end align-items-center">

                    <a href="https://www.instagram.com/psicologia.en.linea.conecta?igsh=ZTVkdThybWswYTdo" target="_blank" rel="noopener noreferrer"><img src={instagramIcon} className="iconSizeFoot mx-3" alt="icono instagram" /></a>
                    <a href="https://www.facebook.com/share/hptgygsXEi7VG9sS/?mibextid=qi2Omg" target="_blank" rel="noopener noreferrer"><img src={faceIcon} className="iconSizeFootFace" alt="icono facebook" /></a>
                    <a href="https://wa.me/573185024375" target="_blank" rel="noopener noreferrer"><img src={whatsIcon} className="iconSizeFoot mx-3" alt="icono whatsApp" /></a>

                </div>
                <div className="footerDevSign col d-flex justify-content-center align-items-center align-middle">
                    <span>® Copyright 2024 Psicología en línea conecta - All rights reserved</span>
                </div>
            </div>
        </div>
    )
}

export default Footer