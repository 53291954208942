import imagenOrientacionPadres from "@images/imagenOrientacionPadres.png"
import { useEffect, useState } from "react"
import { HashLink } from "react-router-hash-link"
import { useSpring, useInView, animated } from "@react-spring/web"
function Orientation() {
    const [screenSizeOrientation, setScreenSizeOrientation] = useState(getCurrentDimensionOr())
    const [ref, springs] = useInView(() => ({
        from: {
            opacity: 0,
            y: "50%",
        },
        to: {
            opacity: 1,
            y: "0%"
        }
    }),{rootMargin:"50% 0%"})
    function getCurrentDimensionOr() {
        if (window.innerWidth < 576) {
            return false
        }
        else {
            return true
        }
    }

    useEffect(() => {
        const updateDimension = () => {
            setScreenSizeOrientation(getCurrentDimensionOr())
        }
        window.addEventListener("resize", updateDimension)
        return (() => {
            window.removeEventListener("resize", updateDimension)
        })
    }, [screenSizeOrientation])

    return (
        <div className="container" id="Orientacion">
            <div className="row py-5">
                <div className="d-flex col justify-content-center subtitles">
                    <h3>Orientación a Padres</h3>
                </div>
            </div>
            <div className={`row ${screenSizeOrientation ? "pb-5" : "pb-2"}`}>
                <animated.div ref={ref} style={{ ...springs, transition: "0.5s ease-in-out" }}>
                    <div className="clearfix orientacionPadresText">
                        {screenSizeOrientation ?
                            <img src={imagenOrientacionPadres} className="col-md-6 float-md-end mb-3 ms-md-3  w-25" alt="..." />
                            : null
                        }
                        <p className="textCustom">
                            Este espacio esta diseñado para orientar a padres y/o cuidadores respecto de las necesidades emocionales y evolutivas de los niños y adolescentes y su impacto en la dinámica familiar.  Este tipo de orientación puede proporcionar estrategias y herramientas para fomentar el desarrollo saludable, mejorar la comunicación en la familia, establecer y comunicar límites adecuados; así como, manejar conflictos.
                            <br />
                            <br />
                            Es un espacio de apoyo donde la profesional brinda una perspectiva objetiva de la situación que acontece y orienta en posibles herramientas a implementar sobre cómo acompañar la gestión emocional en los niños y adolescentes, estrés, falta de cooperación, dificultades en la rutina, entre otras situaciones.
                            <br />
                            <br />
                            Los procesos de adopción y guardas judiciales, son procesos muy movilizantes, en donde cada miembro del núcleo familiar está intentando organizar esta nueva realidad y al miembro que se integra dentro de su dinámica cotidiana. Por este motivo, resulta importante tener un espacio de apoyo emocional, puesto que puede brindar una zona segura para abordar preocupaciones, miedos, expectativas y prepararse para la todo el proceso.
                            <br />
                            El niño y/o adolescente integrado posiblemente pueda necesitar un espacio para elaborar su historia de adopción, identificar y gestionar las emociones que esto le genera y lograr pensarse y ubicarse en un nuevo lugar y dinámica de la familia que lo recibe y posiblemente se convertirá en la suya.
                            <br />
                            La orden judicial brinda la adopción/guarda legal, pero en familia se convierten con el paso del tiempo y la integración.
                            <br />
                            <br />
                            No existen manuales, ni cursos para ser padres; por este motivo tener en ocasiones un apoyo y acompañamiento para expresar tus emociones, temores, adquirir herramientas y generar un espacio de escucha es una opción que se encuentra disponible para ti.

                        </p>

                    </div>
                </animated.div>
            </div>
            <div className="row pb-2">
                <div className="col d-flex justify-content-end">
                    <span className="align-self-end"><HashLink to="/#finalContact" style={{ textDecoration: "none" }}><a href="/#finalContact"><button type="button" className="btn btn-outline-info btn-sm customBtn myBtn">¡ Quiero Contactarme !</button></a></HashLink></span>
                </div>
            </div>
            {screenSizeOrientation ? null :
                <div className="row">
                    <img src={imagenOrientacionPadres} className="imgOrientacion" alt="..." />
                </div>}
        </div>
    )
}

export default Orientation