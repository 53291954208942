import cardImg from "@images/imgAbout.gif"
import psiLogo from "@images/LogoPsychologyToday.png"
import fotoProf1 from "@images/fotoLaura.png"
import fotoProf2 from "@images/fotoElina.png"
import { useInView, animated } from "@react-spring/web"
function About() {
    const [ref, inView] = useInView()
    return (
        <animated.div ref={ref} style={{ opacity: inView ? 1 : 0, transition: "1.5s ease-in-out" }}>
            <div className="container" id="about">
                <div className="row py-5 animate__animated animate__backInDown">
                    <div className="d-flex col justify-content-center subtitles">
                        <p>Acerca de Nosotros</p>
                    </div>
                </div>
                <div className="row aboutRowStyle">
                    <div className="clearfix">
                        <img src={cardImg} className="float-md-end mb-3 ms-md-5 img-thumbnail aboutImg" alt="..." />
                        <div>
                            <p className="textCustom">
                                Psicología en línea Conecta es un consultorio de atención en salud mental que pone en contacto a personas con profesionales en psicología colegiados. Los profesionales brindan servicio de psicología en modalidad virtual, para diversas necesidades como terapia psicológica, acompañamiento de padres y acompañamiento a profesionales. Atendiendo en español.
                                <br />Aquí, encontrarás profesionales latinoamericanos altamente capacitados y comprometidos con el bienestar de las personas que buscan un espacio de atención, acompañamiento u orientación de psicólogos sin restricciones geográficas, desde cualquier país del mundo.
                                <br />Entendemos la importancia de poder acceder a servicios de psicología de calidad desde la comodidad de evitar desplazamientos e independientemente de la ubicación geográfica. Por esto, las sesiones se realizan en modalidad virtual generando un espacio para explorar emociones, pensamientos y acciones en la búsqueda de bienestar y trabajar hacia el análisis de situaciones.
                                <br />Profesionales altamente cualificados y habilitados en sus respectivos países de residencia para el ejercicio profesional de la psicología. Esto es fundamental porque garantiza que los profesionales estén al día con las regulaciones y estándares éticos de la práctica psicológica en sus localidades. Lo que se traduce en una atención de calidad y confianza para los consultantes, independientemente de dónde se encuentren. Cada profesional posee el compromiso de ofrecer un espacio de escucha activa, empatía y acompañamiento profesional, donde puedas explorar inquietudes, superar dificultades y alcanzar metas personales o profesionales.
                                <br /><br />Para agendar una consulta o recibir más información sobre nuestros servicios, no dudes en ponerte en contacto con nosotros a través de nuestro sitio web o nuestras redes sociales. Estamos disponibles para atenderte en horarios flexibles y que puedas priorizar tu salud mental sin importar tu ubicación geográfica.
                                <br /><br />
                                Nuestro equipo son profesionales con diferentes enfoques y experiencia en el ejercicio profesión. Conoce algunas de nuestras profesionales:
                            </p>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-6">
                        <div className="row">
                            <div className="col">
                                <img src={fotoProf1} className="aboutUsPhoto mx-auto d-block" alt="..." />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col">
                                <div id="badge">
                                    <a href="https://www.psychologytoday.com/profile/1061996" target="_blank" rel="noopener noreferrer" className="sx-verified-seal"><img src={psiLogo} className="mx-auto d-block aboutBadgePsychology" alt="..." /></a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-6">
                        <img src={fotoProf2} className="aboutUsPhoto mx-auto d-block" alt="..." />
                    </div>

                </div>

            </div>
        </animated.div>
    )
}

export default About